<template>
    <div>
        <div class="nav">
        <div class="nav-1" @click="toList">账户与安全</div>
        <div class="nav-icon"></div>
        <div class="nav-2">{{title}}</div>
        </div>
        <CompHeader :title="title" />
        <el-form
            class="form" 
            ref="form"
            :model="form" 
            :rules="rules"
            size="large"
            label-width="120px"
            label-position="left"
            require-asterisk-position="right">
          <el-form-item prop="old_password" v-if="reset">
            <template #label>
                <div class="form-label">输入旧密码</div>
            </template>
            <el-input v-model="form.old_password" placeholder="请输入您的当前密码" type="password" />
          </el-form-item>
          <el-form-item prop="new_password">
            <template #label>
                <div class="form-label">设置新密码</div>
            </template>
            <el-input v-model="form.new_password" placeholder="请输入新密码" type="password" />
          </el-form-item>
          <el-form-item prop="new_password_">
            <template #label>
                <div class="form-label">确认新密码</div>
            </template>
            <el-input v-model="form.new_password_" placeholder="请再次输入新密码" type="password" />
          </el-form-item>
        </el-form>
        <el-button class="form-btn" type="primary" @click="submit">保存</el-button>
    </div>
</template>

<script>
import { mapState } from "vuex"
import CompHeader from "@@/common/header.vue";

import { setUserPassword } from "@api/index"
export default{
    components: {
        CompHeader
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId
        }),
        title() {
            return this.reset ? '修改密码' : '设置密码'
        }
    },
    data() {
        return {
            form: {
                old_password: '',
                new_password: '',
                new_password_: ''
            },
            rules: {
                old_password: [
                    {
                        required: true,
                        validator: this.validateOld,
                        trigger: 'change',
                    }
                ],
                new_password: [
                    {
                        required: true,
                        validator: this.validateNew,
                        trigger: 'change',
                    }
                ],
                new_password_: [
                    {
                        required: true,
                        validator: this.validateNew_,
                        trigger: 'change',
                    }
                ]
            },
            reset: true,  // true: 修改密码； false: 设置密码
            oldError: false,
            newError: false,
            loading: false
        }
    },
    created() {
        if (this.$route.path.includes('resetpassword')) {
            this.reset = true
        } else {
            this.reset = false
        }
    },
    methods: {
        toList() {
            this.$router.push('/user/security')
        },
        submit() {
            if (this.loading) return
            this.loading = true
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.form.new_password_ !== this.form.new_password) {
                        this.newError = true
                        this.$refs.form.validate()
                        this.loading = false
                        return 
                    }
                    const obj = {
                        user_id: this.userId,
                        new_password: this.form.new_password,
                    }
                    if (this.reset) {
                        obj.old_password = this.form.old_password
                    }
                    setUserPassword(obj).then(res => {
                        this.loading = false
                        if (res.status == 0) {
                            this.$message.success('修改成功');
                            location.reload()
                        } else {
                            this.$message.error(res.message || '修改失败！');
                            if (res.message == '旧密码错误') {
                                this.oldError = true
                            }
                            return this.$refs.form.validate()
                        }
                    })
                } else {
                    return false
                }
            })
        },
        validateOld(rule, value, callback){
            if (value == '') {
                callback(new Error('输入旧密码'))
            } else if(this.oldError) {
                callback(new Error('旧密码错误'))
                this.oldError = false
            } else {
                callback()
            }
        },
        validateNew(rule, value, callback){
            if (value == '') {
                callback(new Error('请输入新密码'))
            } else {
                callback()
            }
        },
        validateNew_(rule, value, callback){
            if (value == '') {
                callback(new Error('请再次输入新密码'))
            } else if(this.newError) {
                callback(new Error('两次输入密码不一致'))
                this.newError = false
            } else {
                callback()
            }
        },
    }
}
</script>

<style  lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  .nav-1 {
    font-size: 16px;
    font-weight: normal;
    color: #7f7f7f;
    cursor: pointer;
  }
  .nav-icon {
    margin: 0 5px;
    width: 16px;
    height: 16px;
    background: url("~@assets/images/orderlist/logoicon.png") center/cover
      no-repeat;
  }
  .nav-2 {
    font-size: 16px;
    color: #222c37;
    font-weight: bold;
  }
}
.form {
    padding-top: 20px;
    .form-label {
        font-size: 16px;
        color: #1A1A1A;
    }
}
.form-btn {
    float: right;
    cursor: pointer;
}
</style>
